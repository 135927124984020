/*

// jQuery
$(document).ready(function() {
  // code
});

// Vanilla
document.addEventListener("DOMContentLoaded", function() {
  // code
});


// jQuery
$('a').click(function() {
  // code…
})

// Vanilla
[].forEach.call(document.querySelectorAll('a'), function(el) {
    el.addEventListener('click', function() {
    // code…
    })
})

*/



/* DOCUMENT READY ******************************************************************************/
/***********************************************************************************************/

document.addEventListener("DOMContentLoaded", function() {
    // Remove custom site loader on content loaded
    if (document.querySelector('.custom_site_loader').classList.contains('active')) {
        document.querySelector('.custom_site_loader.active').classList.remove('active');
    }

    // Trigger search dropdown + shad
    document.getElementById('trigger_recherche').addEventListener('click', function(evt) {
        if (evt.target.classList.contains('actif')) {
            closeSearch(evt.target)
        } else {
            evt.target.classList.add("actif");
            document.querySelector('.wrap_search_field').classList.add("actif");
            document.getElementById('shad_search').classList.add('actif', 'search_active');
        }
    });

    // Trigger search with results term's close icon
    $('body').on('click', '.search_term_container', function () {
        $('#trigger_recherche').trigger('click');
        $('#search_home').focus();
    });

    $('.close_search, #shad_search').on("click touch", function () {
        closeSearch();
    });

    $('.clear_search').on("click touch", function () {
        clearSearch();
    });

    $('#shad_filter').on("click touch", function () {
        close_filter();
    });

    $("#search_home").on('input', function () {
        var value = $("#search_home").val();

        if (value.length >= 3) {
            $('body').addClass('search_in');
        } else {
            $('body').removeClass('search_in');
        }
    });


    // Handle Shad behavior on active search
    document.getElementById('shad_search').addEventListener('click', function() {
        closeSearch();
    });


    // Search input behavior on keypress submit
    document.getElementById('search_home').addEventListener('keypress', function(e) {
        if (e.key && (e.key == 'Enter')) {
            // If user hits the Enter button, closeSearch
            closeSearch();
        } else {
            // Else, return
            return;
        }
    })


    // Rollover cart & wishlist scrollbars
    if ($('#wishlist_list').length) {
        $('#wishlist_list .holder_scrollable').overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
    }
    if ($('#cart_list').length) {
        $('#cart_list .holder_scrollable').overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
    }

    // Move all products to wishlist --> Rollover wishlist
    $(document).on("click","#btn_top_add_wishlist", function (e) {
        e.preventDefault();
        selectedProducts = [];
        $(".remove_top_wishlist").each(function( index ){
            var id = $(this).attr("data-id");
            selectedProducts.push(id);

        });
        moveProductFromWishlistToCart(selectedProducts);
    });

    // FP Init dropdown description etc thumbs
    if ($('.product_page').length) {
        // Thumbs description and informations click on product page
        displayFpThumb();
    }


    // Wishlist page
    if ($('#sendwishlistbox').length) {
        $('#sendwishlistbox').overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
    }

    // Tunnel move push
    if ($('.cart.step_1').length) {
        updateTunnelElements();
    }

    // Tunnel init scrollbar on stores scrollbar in tunnel delivery
    if ($('body').hasClass('step_2')) {
        setTimeout(function() {
            // $("#choix_relais_relais .sidebar-right").overlayScrollbars();
            $('.sidebar-right').overlayScrollbars({});
            $('.gm-style-iw-d').overlayScrollbars({});
            $("#choix_relais_relais .sidebar-right").css("opacity", "1");
        }, 1000);
    }

    // ABOX close
    $('#abox .close_pop').on('click', function() {
        $( '#abox.lightbox.actif' ).hide();
        closeMultiShad('abox');
    });

    // Onclick "me géolocaliser" fp mobile
    $('.store_locator .storeloc_form .loc_btn').on('click', function() {
        $('.liste_distributeur').removeClass('appear');
    });

    // Ajout Information CLient page Connexion
    function AjoutTexteInformation() {
        var createElementHTML = '<p class="contentAlert"></p>';
            $('.body_login .w-newlogin-form').append(createElementHTML);
            $('.contentAlert').html(Translator.translate('message_information'));
    };
    // Seulement afficher le message si la clé de traduction n'est pas vide
    if (Translator.translate('message_information') != 'message_information') {
        AjoutTexteInformation();
    }

    /*add "*" in placeholder input info form if his not present*/
    var clientForm = document.querySelectorAll(".customer .w-info-form")
    if(clientForm.length > 0) {
        clientForm = clientForm[0].querySelectorAll(".w-input:not(.w-radio-input) .w-input-label")
        for (p of clientForm){
            var strSize = p.innerText.length
            if (p.innerText[strSize - 1] !== "*"){
                p.innerText = p.innerText + "*";
            }
        }
    }



    // Tunnel eclats on products
    if ($('body.cart.step_1 .cart_product_line').length) {
        $('body.cart.step_1 .cart_product_line').each(function() {
            // If ECLAT ROND
            if ($(this).find('.eclat_rond').length) {

                // Add classes to condition style
                if ($(this).find('.eclat_rect').length) {
                    $(this).find('.eclat_rect').addClass('not_alone');
                    $(this).find('.eclat_rond').addClass('not_alone');

                    // Display eclat rect
                    if (!$(this).find('.eclat_rect').is(':visible')) {
                        $(this).find('.eclat_rect').fadeIn(300);
                    }
                } else {
                    $(this).find('.eclat_rond').addClass('alone');
                }

                // Display eclat rond
                if (!$(this).find('.eclat_rond').is(':visible')) {
                    $('.eclat_rond').fadeIn(300);
                }

            } else if ($(this).find('.eclat_privilege').length) {
                // IF ECLAT PRIVILEGE
                // Add classes to condition style
                if ($(this).find('.eclat_rect').length) {
                    $(this).find('.eclat_rect').addClass('not_alone');
                    $(this).find('.eclat_privilege').addClass('not_alone');

                    if (!$(this).find('.eclat_rect').is(':visible')) {
                        $(this).find('.eclat_rect').fadeIn(300);
                    }
                } else {
                    $(this).find('.eclat_privilege').addClass('alone');
                }

                // Display eclat privilege
                if (!$(this).find('.eclat_privilege').is(':visible')) {
                    $(this).find('.eclat_privilege').fadeIn(300);
                }
            } else {
                // Handle if eclat rect only
                if ($(this).find('.eclat_rect').length) {
                    $(this).find('.eclat_rect').addClass('alone');

                    if (!$(this).find('.eclat_rect').is(':visible')) {
                        $(this).find('.eclat_rect').fadeIn(300);
                    }
                }
            }
        });
    }



    // Rayon auto scroll
    if ($('.page_rayon').length > 0) {
            // Set a timer to prevent too much triggering
            var timer;
            var passedNewBlocProd = false;

            $(document).on('scroll', function() {
            // Prevent script from triggering too much and causing blank search results
            if (timer) {
                window.clearTimeout(timer);
            }

            timer = window.setTimeout(function() {
                // If scroll (including page height) is above loader_scroll position, trigger btn to load next page
                if (isInViewport($('.loader_scroll')) && passedNewBlocProd == false) {
                    $('.see_more_product').trigger('click');
                    // next page is loading and passed newblocprod is true to block new triggering
                    passedNewBlocProd = true;
                }
            }, 100);
            // newblocprod is complete and passed to false to allow new newBlocProd generation
            $(window).on("generateNewBlocProd_complete", function () {
                passedNewBlocProd = false;
            })
        });
    }
})

// La fonction isInViewport sert à contrôler si le bouton apparaît à l'écran. Si c'est le cas, elle retourne true et le clic est simulé, sinon non. Cette fonction est appelée dans la condition du clic lors du défilement.

function isInViewport(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

function displayFpThumb() {
    $('.thumblnk').on('click', function (e){
        if ($(this).parent('.thumb').find('.thumb_content').length) {
            if (!$(this).parent('.thumb').find('.thumb_content').hasClass('opened')) {
                $(this).addClass('active');

                $(this).parent('.thumb').find('.thumb_content').addClass('opened');
                $(this).parent('.thumb').find('.thumb_content').slideDown();
            } else {
                $(this).parent('.thumb').find('.thumb_content').slideUp();
                $(this).parent('.thumb').find('.thumb_content').removeClass('opened');
                $(this).removeClass('active');
            }
        } else {
            return;
        }
    });
}

/** function closeSearch
 *
 * A function that remove the open state of the search element in the header
 *
 */

function closeSearch(trigger){
    trigger = (trigger != (undefined || null)) ? trigger : document.getElementById('trigger_recherche');
    if (document.querySelector('.wrap_search_field').classList.contains('actif')) {
        document.getElementById('shad_search').classList.remove('actif', 'search_active');
        document.querySelector('.wrap_search_field').classList.remove('actif');
        trigger.classList.remove('actif');
    }
}

/** function closeSearch
 *
 * A function that clear the search input value
 *
 */
function clearSearch() {
    $('#search_home').val('');
    $('#search_home').focus();
}


/** function triggerShad(shad, action)
 ** function triggerShad_vanilla(shad, action)
 *
 * 2 parameter function that adds or remove a specific shad in the webpage
 *
 * shad : String element targetting the correct shad by its _suffixe
 * action : String element stating the display of the shad "add"/"remove"
 */
function triggerShad(shad, action) {
    shad = shad ? ('#shad_' + shad):'#shad' ;

    if (action && action == 'add') {
        $(shad).addClass('actif');
    } else if (action) {
        $(shad).removeClass('actif');
    } else {
        return;
    }
}
function triggerShad_vanilla(shad, action) {
    shad = shad ? ('shad_' + shad):'shad' ;

    if (action && action == 'add') {
        document.getElementById(shad).classList.add('actif');
    } else if (action) {
        document.getElementById(shad).classList.remove('actif');
    } else {
        return;
    }
}

//For page adresses.php and livraison-choix.php - Hide or display .addDepartment if the selector exists or not
if ( $( "body.customer" ).length || $( "body.cart" ).length ) {
    $( "body" ).on( 'DOMSubtreeModified', ".addrCountry", function () {
        setTimeout( function () {
            if ( $( '.addrDepartmentSelector' ).length === 1) {
                $( '.addrDepartment' ).css({'display' : 'flex'});
            }
            else if ( !$( '.addrDepartmentSelector' ).length ) {
                $( '.addrDepartment' ).css({'display' : 'none'});
            }
        }, 100);
    });
}

// For page "/panier.php?type_redirect=out_of_stock", Hide quantity when no stock
if($("body.cart.step_1").length) {
    $('.cart_product_desc').each(function(){
        if ($(this).find('.cart_product_nostock').length) {
            $(this).find('.qteWrapper').hide();
        }
    });
}