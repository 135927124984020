function faqinit(array_questions) {

    $(document).on('change keyup focus', '#faq_search', function () {

        showTabFaq();

        var search = $(this).val();

        if (search && search.length > 3) {

            $('.search_results li').show().removeClass("hasMatch");
            var array_match = [];
            
            // On affiche uniquement celles qui correspondent à la recherche
            $.each(array_questions, function(i, val) {

                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());	
            
                if (match) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }

            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_results').hide();
            }
    
        } else {		
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });
    
    var click_in_process = false;

    $('.search_results').mousedown(function() {
        click_in_process = true;
    });

    $('.search_results').mouseup(function() {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#faq_search').blur(function() {
        if(!click_in_process) {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    $('#theme_global .theme').on('click', function() {
        var id = $(this).data('id');
        var themeElement = $('.faq_element[data-id="' + id + '"]');
        
        $(this).toggleClass('active').siblings().removeClass('active');
    
        $('.faq_element .faq_title').not(themeElement.find('.faq_title')).slideUp();
    
        themeElement.find('.faq_title').slideToggle();
    
        themeElement.toggleClass('active').siblings('.faq_element').removeClass('active')
            .find('.faq_title').slideUp();
    
        showTabFaq(id, 1);
    });
    
    $('#theme_page .theme').on('click', function() {
        var id = $(this).data('id');
        showTabFaq(id, 1);
    });
}

$(function() {
/* Tunnel FAQ dynamic - Keep last section (contact info) open on page load and hide the question */
    if (($('body.cart').length) && $('.bloc_question').length) {
        $('.faq_theme_wrapper').last().find('.intitule_question').hide();
        $('.faq_theme_wrapper').last().find('.texte_question').show();
    }
})