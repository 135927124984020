$(function () {
    // FP Init color swiper
    if ($('#colorsSwiperFp.swiper-container').length) {
        var colorFPSwiper = new Swiper('#colorsSwiperFp.swiper-container', {
            roundLengths: true,
            navigation: {
                nextEl: '.fp_swiper_button.swiper-button-next',
                prevEl: '.fp_swiper_button.swiper-button-prev',
            },
            breakpoints: {
                4000: {
                    slidesPerView: 13,
                    spaceBetween: 8,
                },
                1950: {
                    slidesPerView: 13,
                    spaceBetween: 8,
                },
                1800: {
                    slidesPerView: 10,
                    spaceBetween: 10,
                },
                1700: {
                    slidesPerView: 8,
                    spaceBetween: 12,
                },
                1500: {
                    slidesPerView: 6,
                    spaceBetween: 14,
                },
                1200: {
                    slidesPerView: 5,
                    spaceBetween: 15,
                },
            }
        })

        $(window).on('resize', colorFPSwiper.update())
    }



    if ($("#gondole_complete .swiper-slide").length > 0) {
        gondole_complete = new Swiper("#gondole_complete", {
            loop: false,
            slidesPerView: 4,
            spaceBetween: 24,
            navigation: {
                nextEl: ".gondole_complete_btn.swiper-button-next",
                prevEl: ".gondole_complete_btn.swiper-button-prev",
            },
            breakpoints: {
                1030: {
                    slidesPerView: 2,
                    spaceBetween: 40
                },
                1320: {
                    slidesPerView: 3,
                    spaceBetween: 40
                }
            }
        });
    }
});

function showsendfriendmodal(product_id, path_web, type) {
    var elname = type === "look_basket" ? "modbox" : "sendfriendbox";
    var actualposition = document.documentElement.scrollTop;

    if (type !== 'look_basket') {
        var psnok = document.getElementById('product_sendfriend_nok');
        var psfok = document.getElementById('product_sendfriend_ok');
        if (psnok) {
            psnok.innerHTML = '';
            psnok.style.display = 'none';
        }
        if (psfok) {
            psfok.innerHTML = '';
            psfok.style.display = 'none';
        }

        prodGetProductDisplay(product_id, path_web, type);

        openMultiShad("sendfriendbox");
        $("#shad_abox").addClass('actif');

    } else {
        show_lightbox(elname);
        $(".loader", "#bloc_add_basket").hide();
    }
}

/**
 * ZOOM PRODUCT PAGE
 * @param slide
 */
function zoomImg(slide) {

    openMultiShad( "zoomBox" );

    var zoom_box_wrapper = new Swiper('#zoom_box_wrapper', {
        slidesPerView: 1,
        navigation: {
            nextEl: '.zoomNext',
            prevEl: '.zoomPrev',
        },
        preloadImages: false,
        lazy: true,
        initialSlide: ((slide) ? (slide - 1) : 0)
    });
}



// Dans la fiche produit affiche un champs email pour que l'ont previenne que le produit est disponible
function lightboxAlertStock() {
    var $taille_id = $("#tailleProd").val(),
        $couleur_id = $("#couleurProd").val();

    if ($taille_id == '') {
        if (!$('#sizeerror').is(':visible')) {
            $('#sizeerror').slideDown();
        }
        return;
    } else if ($couleur_id == '') {
        alert(Translator.translate('js_error_couleur'));
        return;
    }

    var isFormVisible = $('.bloc_add_alert_form').is(':visible');
    var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');
    var isErrorVisible = $('.bloc_add_alert_erreur').is(':visible');
    var btAlertVisible = $('.product_page  #btn_add_alert').is(':visible');

    if (isFormVisible || isMessageVisible || isErrorVisible) {
        if (isFormVisible) {
            $('.bloc_add_alert_form').slideUp('fast');
        }

        if (isMessageVisible) {
            $('.bloc_add_alert_confirmation').slideUp('fast');
        }

        if (isErrorVisible) {
            $('.bloc_add_alert_erreur').slideUp('fast');
        }
    } else {
        $('.bloc_add_alert_form').slideDown('fast');
    }

    if (btAlertVisible) {
        $('.product_page  #btn_add_alert').hide();
    }
}

$('.bloc_add_alert_confirmation button').click(function () {
    $('#bloc_add_alert #btn_add_alert').show();
});