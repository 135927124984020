/* Display of FAQ tabs and their associated content. */
function showTabFaq(node_id, level, question_id) {
    if (question_id !== null && question_id !== undefined) {
        $(".faq_reponse:not(.faq_reponse_" + question_id + ")").slideUp(
            "fast",
            function() {
                $(this).addClass("cache");
            }
        );

        $(".faq_reponse_" + question_id).slideToggle("slow", function() {
            $(this).toggleClass("cache");
        });

        $(".faq_question:not(.faq_question_" + question_id + ")").removeClass(
            "active"
        );
        $(".faq_question_" + question_id).toggleClass("active");
    } else {
        var parent_id = $(".faq_theme_" + node_id).data("parent");

        $(".faq_element:not(.faq_theme_" + node_id + ")").removeClass("active");
        $(".faq_theme_" + node_id).toggleClass("active");
        $(".faq_theme_" + parent_id).toggleClass("active");

        $(
            ".faq_questions:not(.faq_questions_" +
            node_id +
            ", .faq_questions_" +
            parent_id +
            " )"
        ).slideUp("fast", function() {
            $(this).addClass("cache");
        });

        $(".faq_questions_" + node_id).slideToggle("slow", function() {
            $(this).toggleClass("cache");
        });

        $(".faq_level_" + level + ":not(.faq_theme_" + node_id + ")").each(
            function(idx) {
                var id = $(this).data("id");
                $(".faq_level_parent_" + id).slideUp("fast", function() {
                    $(".faq_level_parent_" + id)
                        .addClass("cache")
                        .trigger("classChange");
                });
            }
        );

        $(".faq_level_parent_" + node_id).slideToggle("slow", function() {
            $(".faq_level_parent_" + node_id)
                .toggleClass("cache")
                .trigger("classChange");
        });

        $(".faq_element").on("classChange", function() {
            var id = $(this).data("id");

            if ($(this).hasClass("cache")) {
                $(".faq_level_parent_" + id)
                    .addClass("cache")
                    .trigger("classChange");
            }
        });
    }
}

/* Displays the results of the questions searched */
function researchFaq(node_id, level, question_id) {
    $('.search_results').hide();
    $('.search_results li').show().removeClass("hasMatch");

    if ($('#theme_global').hasClass("actif")) {
        $('#theme_global').removeClass('actif');
        $('#theme_page').addClass('actif');
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }
    showTabFaq(node_id, level, question_id);
    $("html, body").animate({ scrollTop: $(".faq_question_" + question_id + "").offset().top - 100 }, 1000);

    $('#theme_page .theme[data-id='+node_id+']').addClass('active');
}